import type { AxiosError } from 'axios';
import { ChatFooter } from 'Components/Chat/ChatFooter';
import ContextContentContainer from 'Containers/ContextContent';
import ContextHeaderContainer from 'Containers/ContextHeader';
import { MobXProviderContext, observer } from 'mobx-react';
import { fetchOptOutStatus } from 'Modules/conversation/index.requests';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { useConversationStore } from '../../../modules/conversation/index.store';

export const ChatLayout: React.FC = observer(() => {
  const { conversation, person, notification } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const [renderChat, setRenderChat] = React.useState(false);
  const navigate = useNavigate();
  const { reset: resetConversationStore } = useConversationStore();

  React.useEffect(() => {
    conversation
      .loadConversationByIdIfMissingGet(getCurrentConversationId())
      .catch((err: AxiosError) => {
        const resStatus = err.response.status;
        if (resStatus === 403 || resStatus === 404) {
          navigate('/chat');
        } else throw new Error(err.message);
      });
  }, []);

  React.useEffect(() => {
    void (async () => {
      if (conversation.CurrentConversation) {
        if (conversation.CurrentConversation.grouping === 'OneOnOne') {
          const otherParticipant =
            conversation.CurrentConversation.participants.find(
              ({ personId }) => personId !== person.loggedInPersonId
            );

          if (otherParticipant?.phone) {
            try {
              await fetchOptOutStatus(otherParticipant.phone);
            } catch (error) {
              /* TODO: When endpoint is ready, remove this comment
              notification.addAxiosErrorNotification(
                error,
                'Error fetching opt out status'
              );
              */
            }
          }
        }

        setRenderChat(true);
      }
    })();

    return () => {
      resetConversationStore();
    };
  }, [conversation.CurrentConversation?.id, person.loggedInPersonId]);

  return renderChat ? (
    <>
      <ContextHeaderContainer />
      <ContextContentContainer />
      <ChatFooter />
    </>
  ) : null;
});
