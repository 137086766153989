import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import API from '~/api';
import { useConversationStore } from './index.store';
import { OptOutStatusResponse } from './index.types';

export const QUERY_KEY_FETCH_OPT_OUT_STATUS = 'conversation.fetchOptOutStatus';

/*FIXME: For now we need to export this, so we can call on the mobx store (class instance)
 * We will also be able to rely on "useQueries" to parallelize the requests, instead of the Promise.allSettled
 */
export const fetchOptOutStatus = async (phoneNumber: string) => {
  try {
    const { data: optOutStatusResponse } = await API.get<OptOutStatusResponse>(
      API_ENDPOINTS.OptOutStatus(),
      {
        params: { phoneNumbers: [phoneNumber] },
      }
    );

    const isOptedOut10Dlc = optOutStatusResponse.find(
      (contact) => contact.phoneNumber === phoneNumber
    )?.optedOut;

    // FIXME: In the future when we dont need to call this via mobx store, we can use the setAvailabilityPhoneNumberSelector
    // with the useConversationStore hook
    useConversationStore.setState({ isOptedOut10Dlc });

    return isOptedOut10Dlc;
  } catch (error) {
    useConversationStore.setState({ isOptedOut10Dlc: false });

    throw error;
  }
};

export const useFetchOptOutStatus = (phoneNumber: string) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_OPT_OUT_STATUS, phoneNumber],
    queryFn: async () => fetchOptOutStatus(phoneNumber),
  });

export const useConversation = () => ({
  useFetchOptOutStatus,
});
