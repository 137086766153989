import type { TopBarDropdownContextProps } from 'Components/TopBar/contexts/TopBarDropdownContext';
import { DropDownItemTopBar } from 'Components/TopBar/DropDownItemTopBar';
import type { Contact } from 'Models/Contacts';
import React from 'react';

export type createExtrContactOptionsProps = (
  extrContacts: Contact[],
  isNumericSearch?: boolean,
  searchQuery?: string
) => TopBarDropdownContextProps['options'];

export const createExtrContactOptions: createExtrContactOptionsProps = (
  extrContacts,
  isNumericSearch,
  searchQuery
) => {
  return (
    extrContacts
      ?.filter(({ phoneNumbers }) => phoneNumbers.length)
      ?.map(
        ({
          id,
          DisplayName,
          phoneNumbers,
          organization,
          pictureUrl,
          organizationRole: role,
        }) => ({
          key: id.toString(),
          value: id.toString(),
          /**
           * We add the searchQuery to the text prop, so the dropdown displays it
           * TODO: Stop using semantic UI dropdown
           */
          text: isNumericSearch ? searchQuery : DisplayName(),
          variant: 'external-contact',
          phoneNumber: phoneNumbers[0]?.number,
          label: (
            <DropDownItemTopBar
              key={id.toString()}
              userInfo={{
                phoneNumbers: phoneNumbers.map(({ number }) => number),
                keyValue: id.toString(),
              }}
              name={DisplayName()}
              variant="external-contact"
              {...{ organization, pictureUrl, role }}
            />
          ),
        })
      ) || []
  );
};
