import { BodyCopy } from 'Components/shared/BodyCopy';
import styled from 'styled-components';

const MessageInputAreaDisabled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 61px;
  width: 100%;

  ::before {
    content: '';
    background-color: ${({ theme }) => theme.colorsV2.border};
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    margin-top: -61px;
  }
`;

const StyledBodyCopy = styled(BodyCopy)`
  max-width: 75ch;
  text-align: center;
`;

export const Styled = {
  MessageInputAreaDisabled,
  BodyCopy: StyledBodyCopy,
};
