import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ConversationStore, ConversationStoreState } from './index.types';

const initialState: ConversationStoreState = {
  isOptedOut10Dlc: false,
};

const conversationStore = create(
  devtools<ConversationStore>((set) => ({
    ...initialState,
    setIsOptedOut10Dlc: (isOptedOut10Dlc) => set({ isOptedOut10Dlc }),
    reset: () => set(initialState),
  }))
);

export const useConversationStore = conversationStore;
