import { TopBarDropdownContext } from 'Components/TopBar/contexts/TopBarDropdownContext';
import {
  createConversationsOptions,
  createExtrContactOptions,
  bindNumberToOptions,
} from 'Components/TopBar/helpers';
import { debounce } from 'lodash';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { getBvCode } from 'Utils/callValidation/getBvCode';
import { filterSelectedUsers } from '../helpers/filterSelectedUsers';

export const checkIfIsNumericSearch = (
  searchQuery: string,
  phoneNumInvalid: (phoneNumber: string) => boolean
) => {
  const broadVoiceCode = getBvCode(searchQuery);
  const normalizedSearchQuery = searchQuery.replace(/[^0-9+]/g, '');

  const phoneNumber = broadVoiceCode
    ? broadVoiceCode + normalizedSearchQuery
    : normalizedSearchQuery;

  return (
    (broadVoiceCode || !phoneNumInvalid(searchQuery)) && phoneNumber.length >= 3
  );
};

export const useTopBarDropdownFilter = ({
  setShowLoader,
  loadDropDownOptions,
}) => {
  const {
    ui: {
      handleLoadMoreContacts,
      searchContacts,
      topBarContactPageNumber,
      setTopBarSearchValue,
      setTopBarContactPageNumber,
    },
    conversation: { changeConvTopic, FavoriteConversations },
    person: { getSearchContactsTopBar, phoneNumInvalid },
    search,
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { selection, options, setOptions, mapFavoritesOptions } =
    React.useContext(TopBarDropdownContext);

  const [filterInput, setFilterInput] = React.useState('');

  React.useEffect(() => {
    filterFunction(filterInput);
  }, [filterInput]);

  const filterFunction = React.useCallback(
    async (searchQuery: string) => {
      setShowLoader(true);
      setTopBarSearchValue(searchQuery);
      setTopBarContactPageNumber(1);

      try {
        if (!searchQuery) {
          await getSearchContactsTopBar(20, 1, '', '');
          await loadDropDownOptions();
          return;
        }

        const isNumericSearch = checkIfIsNumericSearch(
          searchQuery,
          phoneNumInvalid
        );
        const extrContacts =
          (await getSearchContactsTopBar(20, 1, '', searchQuery)) || [];
        const respConversations = await searchContacts(searchQuery, true);
        const respPerson = await search.getDirectorySearch(
          'USERS',
          searchQuery
        );

        let newOptionResults = createConversationsOptions(
          respPerson?.data?.people || [],
          changeConvTopic,
          isNumericSearch,
          searchQuery
        );

        if (!selection.users.length) {
          newOptionResults = [
            ...newOptionResults,
            ...createConversationsOptions(
              respConversations?.data?.results || [],
              changeConvTopic,
              isNumericSearch,
              searchQuery
            ),
            ...createExtrContactOptions(
              extrContacts,
              isNumericSearch,
              searchQuery
            ),
          ];
        }

        newOptionResults = [
          ...newOptionResults,
          ...mapFavoritesOptions([...FavoriteConversations]),
          ...filterSelectedUsers(selection.users, options),
        ];

        const filteredOptions = newOptionResults.filter(({ text }) =>
          text.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (isNumericSearch) {
          newOptionResults = [
            ...bindNumberToOptions(searchQuery, isNumericSearch),
            ...newOptionResults,
          ];
        }

        if (filteredOptions.length <= 5) {
          await handleLoadMoreContacts(true, false);
          await search.getDirectorySearch(
            'USERS',
            searchQuery,
            topBarContactPageNumber
          );
          await getSearchContactsTopBar(
            20,
            topBarContactPageNumber,
            '',
            searchQuery,
            true
          );
        }

        setOptions(newOptionResults);
      } catch (error) {
        console.error('Error in filterFunction:', error);
      } finally {
        setShowLoader(false);
      }
    },
    [
      setShowLoader,
      setTopBarSearchValue,
      setTopBarContactPageNumber,
      getSearchContactsTopBar,
      loadDropDownOptions,
      phoneNumInvalid,
      searchContacts,
      search,
      handleLoadMoreContacts,
      topBarContactPageNumber,
      changeConvTopic,
      FavoriteConversations,
      selection,
      options,
      mapFavoritesOptions,
      setOptions,
    ]
  );

  const filterDebounce = React.useRef(
    debounce((searchQuery) => setFilterInput(searchQuery), 800)
  ).current;

  return { filterDebounce, filter: setFilterInput };
};
