import type { ConversationModel } from 'Models/index';
import React from 'react';

export type TopBarDropdownVariants =
  | 'group'
  | 'internal-contact'
  | 'external-contact'
  | 'adhoc-phone-number';

export type TopBarDropdownContextProps = {
  makeCall: (
    data: { personId?: string; phoneNumbers?: string[] },
    e?: any
  ) => Promise<void>;
  options: {
    key: string;
    value: string;
    text: string;
    label: React.ReactNode;
    variant: TopBarDropdownVariants;
    phoneNumber?: string;
  }[];
  setOptions: React.Dispatch<
    React.SetStateAction<TopBarDropdownContextProps['options']>
  >;
  selection: {
    users: string[];
    variant: Extract<
      TopBarDropdownVariants,
      'internal-contact' | 'adhoc-phone-number'
    >;
  };
  setSelection: React.Dispatch<
    React.SetStateAction<TopBarDropdownContextProps['selection']>
  >;
  favoriteList: Record<string, true>;
  mapFavoritesOptions: (
    conversationList: ConversationModel[]
  ) => TopBarDropdownContextProps['options'];
};
export const TopBarDropdownContext =
  React.createContext<TopBarDropdownContextProps>({
    makeCall: () => Promise.resolve(),
    options: [],
    setOptions: () => {},
    selection: { users: [], variant: null },
    setSelection: () => {},
    favoriteList: {},
    mapFavoritesOptions: () => [],
  });
