import { MessageInputArea } from 'Components/Chat/ChatFooter/MessageInputArea';
import { MobXProviderContext, observer } from 'mobx-react';

import { useConversationStore } from 'Modules/conversation/index.store';
import { usePersonStore } from 'Modules/person/index.store';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { Styled } from './index.styles';

export const ChatFooter: React.FC = observer(() => {
  const { conversation, participant } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const { isOptedOut10Dlc } = useConversationStore();
  const { campaignEnabled10Dlc } = usePersonStore();

  if (
    !(
      conversation?.CurrentConversation ||
      window.location.pathname.includes('adHocGroup')
    )
  )
    return null;

  const isGroupChat =
    conversation.CurrentConversation?.grouping === 'Channel' ||
    conversation.CurrentConversation?.grouping === 'Group';

  const isSMSConversation =
    conversation.CurrentConversation?.id &&
    !!participant
      .selectOtherParticipants(conversation.CurrentConversation?.id)
      ?.some(({ phone }) => phone);

  if (isGroupChat && !conversation.CurrentConversation?.isActiveParticipant) {
    return (
      <Styled.MessageInputAreaDisabled>
        <Styled.BodyCopy>
          You can't send messages to this group because you are not a member
          anymore.
        </Styled.BodyCopy>
      </Styled.MessageInputAreaDisabled>
    );
  } else if (isOptedOut10Dlc) {
    return (
      <Styled.MessageInputAreaDisabled>
        <Styled.BodyCopy>
          You can't text this number because they opted-out from receiving
          messages.
        </Styled.BodyCopy>
      </Styled.MessageInputAreaDisabled>
    );
  } else if (campaignEnabled10Dlc === false && isSMSConversation) {
    return (
      <Styled.MessageInputAreaDisabled>
        <Styled.BodyCopy>
          Your number is not enabled for SMS messaging. Contact your account
          admin to register it.
        </Styled.BodyCopy>
      </Styled.MessageInputAreaDisabled>
    );
  } else {
    return <MessageInputArea />;
  }
});
